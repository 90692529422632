import React from 'react';

function TrophyChallengeFilled(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='31'
      height='30'
      viewBox='0 0 31 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.125 1.875H24.875C23.9469 8.37563 23.9375 20.625 15.5 20.625C7.0625 20.625 7.05313 8.37563 6.125 1.875ZM6.125 30C6.125 29.5027 6.32254 29.0258 6.67417 28.6742C7.02581 28.3225 7.50272 28.125 8 28.125H23C23.4973 28.125 23.9742 28.3225 24.3258 28.6742C24.6775 29.0258 24.875 29.5027 24.875 30H6.125ZM9.875 28.125C9.875 27.6277 10.0725 27.1508 10.4242 26.7992C10.7758 26.4475 11.2527 26.25 11.75 26.25H19.25C19.7473 26.25 20.2242 26.4475 20.5758 26.7992C20.9275 27.1508 21.125 27.6277 21.125 28.125H9.875Z'
        fill={props.fill ?? '#290159'}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.9375 5.625C22.9429 5.625 21.9891 6.02009 21.2859 6.72335C20.5826 7.42661 20.1875 8.38044 20.1875 9.375C20.1875 10.3696 20.5826 11.3234 21.2859 12.0267C21.9891 12.7299 22.9429 13.125 23.9375 13.125C24.9321 13.125 25.8859 12.7299 26.5891 12.0267C27.2924 11.3234 27.6875 10.3696 27.6875 9.375C27.6875 8.38044 27.2924 7.42661 26.5891 6.72335C25.8859 6.02009 24.9321 5.625 23.9375 5.625ZM18.3125 9.375C18.3125 8.63631 18.458 7.90486 18.7407 7.22241C19.0234 6.53995 19.4377 5.91985 19.96 5.39752C20.4824 4.87519 21.1024 4.46086 21.7849 4.17818C22.4674 3.8955 23.1988 3.75 23.9375 3.75C24.6762 3.75 25.4076 3.8955 26.0901 4.17818C26.7726 4.46086 27.3926 4.87519 27.915 5.39752C28.4373 5.91985 28.8516 6.53995 29.1343 7.22241C29.417 7.90486 29.5625 8.63631 29.5625 9.375C29.5625 10.8668 28.9699 12.2976 27.915 13.3525C26.8601 14.4074 25.4293 15 23.9375 15C22.4457 15 21.0149 14.4074 19.96 13.3525C18.9051 12.2976 18.3125 10.8668 18.3125 9.375ZM7.0625 5.625C6.06794 5.625 5.11411 6.02009 4.41085 6.72335C3.70759 7.42661 3.3125 8.38044 3.3125 9.375C3.3125 10.3696 3.70759 11.3234 4.41085 12.0267C5.11411 12.7299 6.06794 13.125 7.0625 13.125C8.05706 13.125 9.01089 12.7299 9.71415 12.0267C10.4174 11.3234 10.8125 10.3696 10.8125 9.375C10.8125 8.38044 10.4174 7.42661 9.71415 6.72335C9.01089 6.02009 8.05706 5.625 7.0625 5.625ZM1.4375 9.375C1.4375 8.63631 1.58299 7.90486 1.86568 7.22241C2.14836 6.53995 2.56269 5.91985 3.08502 5.39752C3.60735 4.87519 4.22745 4.46086 4.90991 4.17818C5.59236 3.8955 6.32381 3.75 7.0625 3.75C7.80119 3.75 8.53264 3.8955 9.21509 4.17818C9.89755 4.46086 10.5176 4.87519 11.04 5.39752C11.5623 5.91985 11.9766 6.53995 12.2593 7.22241C12.542 7.90486 12.6875 8.63631 12.6875 9.375C12.6875 10.8668 12.0949 12.2976 11.04 13.3525C9.98508 14.4074 8.55434 15 7.0625 15C5.57066 15 4.13992 14.4074 3.08502 13.3525C2.03013 12.2976 1.4375 10.8668 1.4375 9.375Z'
        fill={props.fill ?? '#290159'}
      />
      <path
        d='M13.625 18.75H17.375V26.25H13.625V18.75Z'
        fill={props.fill ?? '#290159'}
      />
      <path
        d='M19.25 20.625C19.25 21.66 17.5719 22.5 15.5 22.5C13.4281 22.5 11.75 21.66 11.75 20.625C11.75 19.59 13.4281 18.75 15.5 18.75C17.5719 18.75 19.25 19.59 19.25 20.625Z'
        fill={props.fill ?? '#290159'}
      />
    </svg>
  );
}

TrophyChallengeFilled.displayName = 'TrophyChallenge';

export default TrophyChallengeFilled;
