import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { emailSupport } from '../../../../config/env.json';
import { ButtonTypes } from '../ui/Button/button-types';
import Button from '../ui/Button';
import { Modal } from '../ui/Modal';

import './delete-modal.css';

type DeleteModalProps = {
  readonly show: boolean;
  readonly deleteAccount: () => void;
  readonly onHide: () => void;
};

function DeleteModal({
  show,
  onHide,
  deleteAccount
}: DeleteModalProps): JSX.Element {
  const { t } = useTranslation();

  const email = String(emailSupport);

  return (
    <Modal.Root keyboard={true} onHide={onHide} show={show}>
      <Modal.Header closeButton onHide={onHide}>
        {t('settings.danger.delete-title')}
      </Modal.Header>
      <Modal.Body>
        <p>{t('settings.danger.delete-p1')}</p>
        <p>
          <strong>{t('settings.danger.delete-p2')}</strong>
        </p>
        <p>
          <Trans i18nKey='settings.danger.delete-p3'>
            <a href={`mailto:${email}`} title={email}>
              {{ email }}
            </a>
          </Trans>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} type='button' buttonType={ButtonTypes.Light}>
          {t('settings.danger.nevermind')}
        </Button>

        <Button
          onClick={deleteAccount}
          type='button'
          buttonType={ButtonTypes.Danger}
        >
          {t('settings.danger.delete-confirm')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
