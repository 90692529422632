"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidUsername = exports.isHttpStatusCode = exports.usernameIsHttpStatusCode = exports.usernameTooShort = exports.validationSuccess = exports.invalidCharError = void 0;
exports.invalidCharError = {
    valid: false,
    error: 'contains invalid characters'
};
exports.validationSuccess = { valid: true, error: null };
exports.usernameTooShort = {
    valid: false,
    error: 'is too short'
};
exports.usernameIsHttpStatusCode = {
    valid: false,
    error: 'is a reserved error code'
};
const validCharsRE = /^[a-zA-Z0-9\-_+]*$/;
const isHttpStatusCode = (str) => {
    const output = parseInt(str, 10);
    return !isNaN(output) && output >= 100 && output <= 599;
};
exports.isHttpStatusCode = isHttpStatusCode;
const isValidUsername = (str) => {
    if (!validCharsRE.test(str))
        return exports.invalidCharError;
    if (str.length < 3)
        return exports.usernameTooShort;
    if ((0, exports.isHttpStatusCode)(str))
        return exports.usernameIsHttpStatusCode;
    return exports.validationSuccess;
};
exports.isValidUsername = isValidUsername;
