import React from 'react';

import FullWidthRow from '../helpers/full-width-row';

import './section-header.css';

type SectionHeaderProps = {
  children: string | React.ReactNode | React.ReactElement;
  dangerStyle?: boolean;
};

function SectionHeader({
  children,
  dangerStyle
}: SectionHeaderProps): JSX.Element {
  const className = `section-header-text ${
    dangerStyle ? 'danger-title text-center' : 'text-center'
  }`;

  return (
    <FullWidthRow>
      <h2 className={className}>{children}</h2>
    </FullWidthRow>
  );
}

SectionHeader.displayName = 'SectionHeader';

export default SectionHeader;
